<script setup lang="ts">
import type { AvatarSize } from '#ui/types'

const { url } = useImageStorage()

const props = withDefaults(
  defineProps<{
    avatarUrl?: string
    showChip?: boolean
    isLargeChip?: boolean
    podiumPoint?: number
    avatarSize?: AvatarSize
    avatarSizeClass?: string
    badgeClass?: string
  }>(),
  {
    avatarUrl: '',
    showChip: true,
    isLargeChip: true,
    podiumPoint: 0,
    avatarSize: '3xl',
    avatarSizeClass: 'w-16 h-16',
    badgeClass: '',
  },
)

const chipBaseClass = () => {
  if (props.podiumPoint) {
    switch (props.isLargeChip) {
      case true:
        return '-mx-1.5 -my-0.5 py-3 px-1.5 rounded-full ring-2 ring-white dark:ring-white'
      default:
        return '-mx-0.5 -my-0.5 w-10 h-5 px-0.5 py-0.5 rounded-full ring-2 ring-white dark:ring-white'
    }
  }
  else {
    switch (props.isLargeChip) {
      case true:
        return 'w-5 h-5 -mx-1.5 -my-0.5 py-0 px-0 rounded-full ring-2 ring-white dark:ring-white'
      default:
        return 'w-5 h-5 -mx-0.5 -my-0.5 py-0 px-0 rounded-full ring-2 ring-white dark:ring-white'
    }
  }
}
</script>

<template>
  <WMChip
    :show="showChip"
    size="sm"
    position="bottom-right"
    inset
    :ui="{
      base: chipBaseClass(),
      background: podiumPoint ? 'bg-black dark:bg-black' : 'bg-wnmPrimary dark:bg-wnmPrimary',
    }"
  >
    <WMAvatar
      :src="avatarUrl"
      img-class="object-cover"
      :size="avatarSize"
      alt="avatar"
      :ui="{ size: { '3xl': avatarSizeClass } }"
    />
    <template #content>
      <ACrown
        v-if="podiumPoint"
        :podium-point="podiumPoint"
        :badge-class="badgeClass"
      />
      <NuxtImg
        v-else
        :src="url(true, '/assets/logo/wnm_logo_white.svg')"
        class="w-4 h-4"
      />
    </template>
  </WMChip>
</template>
