<script setup lang="ts">
const user = useSupabaseUser()

withDefaults(
  defineProps<{
    menuData?: Database['public']['Tables']['menuCategory']['Row'][]
  }>(),
  {
    menuData: () => ([] as Database['public']['Tables']['menuCategory']['Row'][]),
  },
)

defineEmits([
  'click:menu',
])

const visibleSubMenu = (index: number) => {
  switch (index) {
    case 7:
      return user.value?.id ? true : false
    default:
      return index > 7
  }
}
</script>

<template>
  <WMAccordion
    :items="menuData?.map(item => ({
      ...item,
      to: item.to || undefined,
    }))"
    :ui="{ item: { padding: 'p-0' } }"
  >
    <template #default="{ item, index }">
      <WMButton
        v-show="visibleSubMenu(index)"
        color="gray"
        variant="ghost"
        :ui="{ base: 'my-2', rounded: 'rounded-none', padding: { sm: 'p-0 py-1.5' } }"
        @click="() => $emit('click:menu', item.menuList.length, item.to)"
      >
        <span class="text-lg font-semibold text-gray05Light dark:text-gray05Dark turncate">
          {{ item.title }}
        </span>
      </WMButton>
    </template>
  </WMAccordion>
</template>
