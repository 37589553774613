<script lang="ts" setup>
import type { FilterDatabase } from '@/types/supabaseFilter'
import type { MotorcycleDatabase } from '@/types/supabaseMotorcycle'

const { t } = useLocale()
const { url } = useImageStorage()

const colorMode = useColorMode()

type HotMotorcycleReview = MotorcycleDatabase['motorcycle']['Views']['viewReviews']['Row']
  & { viewsList: MotorcycleDatabase['motorcycle']['Tables']['reviewCount']['Row'][] }
  & { profiles: Database['public']['Tables']['profiles']['Row']
    & { avatar_url: string }
  }
  & { vehicleModel: MotorcycleDatabase['motorcycle']['Tables']['vehicleModel']['Row'] }
  & { manufacturers: FilterDatabase['filter']['Tables']['manufacturers']['Row'] }
  & { motoGenre: FilterDatabase['filter']['Tables']['motoGenre']['Row'] }
  & { reviewImageList: MotorcycleDatabase['motorcycle']['Tables']['reviewImageList']['Row'][] }

type HotBoardArticle = Database['public']['Views']['viewBoardArticle']['Row']
  & { profiles: Database['public']['Tables']['profiles']['Row']
    & { avatarList: Database['public']['Tables']['avatarList']['Row'] }
  }
  & { articleComment: Database['public']['Tables']['articleComment']['Row'][] }
  & { articleLike: Database['public']['Tables']['articleLike']['Row'][] }
  & { articleViewCount: Database['public']['Tables']['articleViewCount']['Row'][] }
  & { mainArticleImage: Database['public']['Tables']['articleImageList']['Row'] }

type HotMarketArticle = Database['public']['Views']['viewMarketArticle']['Row']
  & { profiles: Database['public']['Tables']['profiles']['Row']
    & { avatarList: Database['public']['Tables']['avatarList']['Row'] }
  }
  & { marketStatus: FilterDatabase['filter']['Tables']['marketStatus']['Row'] }
  & { articleComment: Database['public']['Tables']['articleComment']['Row'][] }
  & { articleLike: Database['public']['Tables']['articleLike']['Row'][] }
  & { articleViewCount: Database['public']['Tables']['articleViewCount']['Row'][] }
  & { mainArticleImage: Database['public']['Tables']['articleImageList']['Row'] }

const props = withDefaults(
  defineProps<{
    isSignIn?: boolean
    hotMotorcycleReviewList?: HotMotorcycleReview[] | null
    hotBoardArticleList?: HotBoardArticle[] | null
    hotMarketArticleList?: HotMarketArticle[] | null
  }>(),
  {
    isSignIn: false,
    hotMotorcycleReviewList: () => [],
    hotBoardArticleList: () => [],
    hotMarketArticleList: () => [],
  },
)

const emits = defineEmits([
  'click:hotest-review',
  'click:hotest-article',
  'click:search-meeting',
  'click:write-post',
])

const selectArticleTabIndex = ref(0)

const meetingCategory = ref([
  { label: t('main.hotMenu.ownerReview') },
  { label: t('main.hotMenu.board') },
  { label: t('main.hotMenu.market') },
])

const switchMeetingData = () => {
  switch (selectArticleTabIndex.value) {
    case 0:
      return props.hotMotorcycleReviewList ?? []
    case 1:
      return props.hotBoardArticleList ?? []
    case 2:
      return props.hotMarketArticleList ?? []
    default:
      return []
  }
}

const switchEmptyData = () => {
  switch (selectArticleTabIndex.value) {
    case 0:
      return { message: t('main.noHotestArticle.review'), buttonLabel: t('button.writeOwnerReview'), path: '/review/motorcycle/write/init', emptyImageUrl: '/assets/icon/motorcycle.svg', emptyImageSize: 60 }
    case 1:
      return { message: t('main.noHotestArticle.article'), buttonLabel: t('button.writeBoard'), path: '/board/write', emptyImageUrl: '/assets/icon/article.svg', emptyImageSize: 60 }
    case 2:
      return { message: t('main.noHotestArticle.article'), buttonLabel: t('button.writeMarket'), path: '/market/write', emptyImageUrl: '/assets/icon/cart.svg', emptyImageSize: 60 }
    default:
      return { message: '', buttonLabel: '', path: '', emptyImageUrl: '', emptyImageSize: 0 }
  }
}

const switchMeetingSearchButton = () => {
  switch (selectArticleTabIndex.value) {
    case 0:
      return { label: t('button.more'), detailPath: '/review/motorcycle/list', morePath: '/review/motorcycle' }
    case 1:
      return { label: t('button.more'), detailPath: '/board/free', morePath: '/board/free' }
    case 2:
      return { label: t('button.more'), detailPath: '/market/over125Manual', morePath: '/market/over125Manual' }
    default:
      return { label: '', morePath: '' }
  }
}

const clickHotestItem = (item: HotMotorcycleReview | HotBoardArticle | HotMarketArticle) => {
  !selectArticleTabIndex.value
    ? emits('click:hotest-review', item.id, (item as HotMotorcycleReview).vehicle_model_id, switchMeetingSearchButton().detailPath)
    : emits('click:hotest-article', item.id, switchMeetingSearchButton().detailPath)
}
</script>

<template>
  <div class="w-full flex flex-col gap-y-2.5 px-5 my-5">
    <div class="w-full flex flex-col gap-y-1.5">
      <p class="text-xl font-semibold text-gray07Light dark:text-gray07Dark break-keep">
        {{ $t('main.hotestArticleTitle') }}
      </p>
      <p class="text-sm font-light text-gray05Light dark:text-gray05Dark break-keep">
        {{ $t('main.last7Days') }}
      </p>
      <WMTabs
        v-model="selectArticleTabIndex"
        :items="meetingCategory"
        size="xl"
        :ui="{
          wrapper: 'space-y-0 w-full overflow-x-scroll border-b-2 border-gray02Light dark:border-gray02Dark',
          list: {
            base: 'flex-forced gap-x-2.5',
            width: 'w-fit',
            height: 'h-fit',
            padding: '',
            background: 'bg-transparent dark:bg-transparent',
            tab: {
              base: 'text-lg w-fit flex',
              size: 'text-lg',
              height: '',
              width: 'w-fit',
              active: 'leading-6 font-semibold text-gray07Light dark:text-gray07Dark bg-gray01Light dark:bg-gray01Dark border-b-2 rounded-none border-gray07Light dark:border-gray07Dark',
              inactive: 'leading-6 text-gray04Light dark:text-gray04Dark bg-transparent border-b-2 border-transparent rounded-none',
              padding: 'py-4 px-3',
            },
          },
        }"
      />
    </div>
    <div class="w-full flex flex-col gap-y-2.5">
      <AEmptyContent
        :display="!switchMeetingData().length"
        class="flex flex-col gap-y-4 items-center py-30 px-8"
        empty-class="gap-y-4"
        :empty-image-url="switchEmptyData().emptyImageUrl"
        :empty-image-size="switchEmptyData().emptyImageSize"
        :message="switchEmptyData().message"
        use-button
        button-use-leading
        button-leading-image-url="/assets/icon/lock_button_green.svg"
        button-leading-image-class="w-4 h-4"
        :button-text="switchEmptyData().buttonLabel"
        @click:no-content-button="() => $emit('click:write-post', switchEmptyData().path)"
      />
      <div
        v-for="(item, index) in switchMeetingData()"
        v-show="switchMeetingData().length"
        :key="index"
        class="w-full flex flex-col gap-y-2 py-5 cursor-pointer"
        @click="clickHotestItem(item)"
      >
        <MainHotestReview
          v-if="!selectArticleTabIndex"
          :hotest-review="(item as HotMotorcycleReview)"
        />
        <MainHotestArticle
          v-else
          :hotest-article="(item as HotBoardArticle | HotMarketArticle)"
        />
      </div>
      <AButton
        :custom-class="'w-full gap-x-0'"
        label-class="py-0.5"
        button-block
        button-variant="outline"
        button-size="xl"
        :use-leading="!isSignIn"
        use-trailing
        :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/lock_button.svg' : '/assets/icon/lock_button_dark.svg')"
        image-class="w-4 h-4 mr-1"
        :image-trail-url="url(true, '/assets/icon/arrow_right_middle.svg')"
        image-trail-class="w-6 h-6"
        :button-text="switchMeetingSearchButton().label"
        @click="() => $emit('click:search-meeting', switchMeetingSearchButton().morePath)"
      />
    </div>
  </div>
</template>
