<script setup lang="ts">
const { url } = useImageStorage()

withDefaults(
  defineProps<{
    hideTooltip?: boolean
    customClass?: string
    customStyle?: { [key: string]: string } | string
    shortcutsText?: Array<string>
    menuText?: string
    iconUrl?: string
  }>(),
  {
    hideTooltip: false,
    customClass: '',
    customStyle: '',
    shortcutsText: () => [],
    menuText: '',
    iconUrl: '',
  },
)
</script>

<template>
  <WMTooltip
    :text="menuText"
    :style="customStyle"
    :shortcuts="shortcutsText"
    :ui="{
      base: !menuText || hideTooltip ? 'hidden' : '[@media(pointer:coarse)]:hidden h-6 px-2 py-1 text-xs font-normal truncate relative',
    }"
  >
    <div class="w-[72px] flex flex-col items-center gap-y-2 cursor-pointer">
      <div class="max-w-12 flex items-center justify-center rounded-xl bg-gray02Light dark:bg-gray02Dark">
        <NuxtImg
          :src="url(true, `/assets/menu/${iconUrl}.svg`) ?? ''"
          class="w-12 h-12"
        />
      </div>
      <span class="text-center text-sm break-keep">
        {{ menuText }}
      </span>
    </div>
  </WMTooltip>
</template>
