export const useHaversine = () => {
  const haversine = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371000 // 지구의 평균 반지름 (단위: 미터)

    const phi1 = lat1 * (Math.PI / 180)
    const phi2 = lat2 * (Math.PI / 180)
    const deltaPhi = (lat2 - lat1) * (Math.PI / 180)
    const deltaLambda = (lon2 - lon1) * (Math.PI / 180)

    const a = Math.sin(deltaPhi / 2) ** 2 + Math.cos(phi1) * Math.cos(phi2) * Math.sin(deltaLambda / 2) ** 2
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c
  }

  return {
    haversine,
  }
}
