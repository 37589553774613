<script setup lang="ts">
const { comma } = useUi()
const { url } = useImageStorage()

const { userData } = storeToRefs(useUserDataStore())

withDefaults(
  defineProps<{
    menuList?: Database['public']['Tables']['menuList']['Row'][]
  }>(),
  {
    menuList: () => ([] as Database['public']['Tables']['menuList']['Row'][]),
  },
)

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  required: true,
})

const avatarUrl = ref(url(true, '/assets/logo/wnm_logo.svg'))

if (userData.value?.avatar_id) {
  userData.value.avatarList.map(async (avatar) => {
    if (avatar.id === userData.value?.avatar_id) {
      avatarUrl.value = avatar.url ?? ''
    }
  })
}

const navigateMyProfile = () => {
  navigateTo('/profile')
  menuOpenTrigger.value = false
}

const navigateMyMenu = (to: string) => {
  navigateTo(to)
  menuOpenTrigger.value = false
}

const navigateHotMenu = (type: string) => {
  switch (type) {
    case 'point':
      navigateTo('/my/point')
      break
    case 'pointUse':
      navigateTo('/point')
      break
    case 'exchange':
      navigateTo('/settlement')
      break
  }

  menuOpenTrigger.value = false
}
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div
      class="w-full py-1.5 flex items-center gap-4 cursor-pointer border-b border-gray03Light dark:border-gray03Dark hover:text-neutral-500 hover:rounded-lg transition ease-in-out"
      @click="navigateMyProfile"
    >
      <AUserAvatar
        :show-chip="userData?.podium_point ? true : false"
        :podium-point="userData?.podium_point ?? 0"
        :avatar-url="avatarUrl"
      />
      <div class="flex flex-col">
        <span class="text-xl font-semibold">
          {{ $t('login.sir', { nickname: userData?.nickname ?? '유저' }) }}
        </span>
        <span class="text-sm">
          {{ userData?.email ?? '이메일을 설정해주세요.' }}
        </span>
      </div>
      <div class="flex-auto" />
      <NuxtImg
        :src="url(true, '/assets/icon/arrow_right_large.svg')"
        class="w-6 h-6 ms-auto transform transition-transform duration-200 text-gray05Light dark:text-gray05Dark"
      />
    </div>
    <div
      class="w-full flex items-center cursor-pointer text-gray05Light dark:text-gray05Dark hover:text-gray04Light dark:hover:text-gray04Dark transition ease-in-out duration-200 break-keep"
      @click="navigateHotMenu('point')"
    >
      <span class="text-base">
        {{ $t('text.point') }}
      </span>
      <div class="flex-auto" />
      <div class="flex items-center gap-x-2">
        <NuxtImg
          :src="url(true, '/assets/icon/point.svg')"
          class="w-6 h-6"
        />
        <span class="text-lg font-semibold">
          {{ comma(userData?.point ?? 0) }}
        </span>
        <NuxtImg
          :src="url(true, '/assets/icon/arrow_right_large.svg')"
          class="w-6 h-6 ms-auto transform transition-transform duration-200 text-gray05Light dark:text-gray05Dark"
        />
      </div>
    </div>
    <div class="w-full flex gap-x-4">
      <AButton
        custom-class="w-full ring-neutral-200 h-[50px] font-semibold"
        button-block
        button-variant="soft"
        button-color="green"
        button-confirm-color="primary"
        button-size="xl"
        :button-text="$t('button.pointUse')"
        @click="navigateHotMenu('pointUse')"
      />
      <AButton
        custom-class="w-full ring-neutral-200"
        button-block
        button-variant="soft"
        button-confirm-color="gray"
        button-size="xl"
        :button-text="$t('button.fundWithdrawal')"
        @click="navigateHotMenu('exchange')"
      />
    </div>
  </div>
  <div class="flex flex-col gap-y-4">
    <div class="flex items-center gap-x-1">
      <span class="text-base font-semibold break-keep text-gray05Light dark:text-gray05Dark">
        {{ $t('text.my') }}
      </span>
    </div>
    <div class="flex flex-wrap gap-5">
      <AIconMenuButton
        v-for="(menu, index) in menuList"
        :key="index"
        :menu-text="menu.title ?? ''"
        :icon-url="menu.icon_name ?? ''"
        @click="navigateMyMenu(menu.to ?? '')"
      />
    </div>
  </div>
</template>
