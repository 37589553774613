import VueDOMPurifyHTML from 'vue-dompurify-html'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueDOMPurifyHTML, {
    namedConfigurations: {
      videos: {
        ADD_TAGS: ['iframe', 'kakao-map', 'div', 'img', 'h3', 'p', 'a'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'lat', 'lng', 'width', 'height', 'src', 'alt', 'target', 'href', 'class', 'noreferrer', 'noreferrer', 'rel', 'onclick'],
      },
    },
    hooks: {
      uponSanitizeElement: async (node: any, data: any) => {
        // allows embedded YouTube videos
        if (data.tagName === 'div[data-youtube-video]') {
          const src = node.getAttribute('src') || ''
          if (!src.startsWith('https://www.youtube.com/embed/')) {
            return node.parentNode?.removeChild(node)
          }
        }
        // allows kakao-map elements
        if (data.tagName === 'kakao-map') {
          return
        }
        // allows img elements with onclick
        if (data.tagName === 'img') {
          node.setAttribute('style', 'cursor: pointer;')
          node.setAttribute('onclick', `showImageModal('${node.getAttribute('src')}')`)
        }
      },
    },
  })
})
