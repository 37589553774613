<script setup lang="ts">
defineEmits([
  'select:radius',
])

const changeRadiusModalTrigger = defineModel('changeRadiusModalTrigger', {
  type: Boolean,
  default: false,
})

const selectRadius = ref(3000)

const radiusList = ref([
  {
    label: '3km',
    value: 3000,
  },
  {
    label: '5km',
    value: 5000,
  },
  {
    label: '10km',
    value: 10000,
  },
  {
    label: '20km',
    value: 20000,
  },
  {
    label: '30km',
    value: 30000,
  },
  {
    label: '50km',
    value: 50000,
  },
])
</script>

<template>
  <DialogConfirm
    v-model:confirm-modal-trigger="changeRadiusModalTrigger"
    :title="$t('dialog.confirm.changeSearchRadius')"
    :confirm-text="$t('button.changeLong')"
    :cancel-text="$t('button.cancel')"
    prevent-close
    @confirm="() => $emit('select:radius', selectRadius)"
    @cancel="() => changeRadiusModalTrigger = false"
  >
    <WMSelect
      v-model="selectRadius"
      select-class="w-max-[140px] truncate"
      size="md"
      :options="radiusList"
      :placeholder="$t('label.searchRadius')"
    />
  </DialogConfirm>
</template>
