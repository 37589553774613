import { defineStore } from 'pinia'

export const useUserDataStore = defineStore('userDataStore', () => {
  /**
   * ! Pinia State !
   *
   * @param userCoreId 사용자 UID
   * @param otherUserCoreId 다른 사용자 UID
   * @param userData 사용자 정보
   *
   * @param mainSearchExpertRadius 메인 홈 검색 전문가 반경
   * @param mainSearchLocationAddress 메인 홈 검색 위치 주소
   * @param mainSearchLocationLatitude 메인 홈 검색 위치 위도
   * @param mainSearchLocationLongitude 메인 홈 검색 위치 경도
   *
   */

  const userCoreId = ref('')
  const otherUserCoreId = ref('')

  const userData = ref<ProfilesSupabase>()

  const mainSearchExpertRadius = ref(3000)
  const mainSearchLocationAddress = ref('영등포구')
  const mainSearchLocationLatitude = ref(37.526618)
  const mainSearchLocationLongitude = ref(126.919666)

  watch(userData, () => {
    if (userData.value) {
      userCoreId.value = userData.value?.id ?? ''

      if (userData.value?.mainLocation) {
        mainSearchLocationAddress.value = userData.value?.mainLocation.simple_address ?? '영등포구'
        mainSearchLocationLatitude.value = userData.value?.mainLocation.latitude ?? 37.526618
        mainSearchLocationLongitude.value = userData.value?.mainLocation.longitude ?? 126.919666
      }
    }
  }, {
    immediate: true,
  })

  return {
    userCoreId,
    otherUserCoreId,
    userData,
    mainSearchExpertRadius,
    mainSearchLocationAddress,
    mainSearchLocationLatitude,
    mainSearchLocationLongitude,
  }
}, {
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage(),
  },
})
