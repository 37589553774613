<script setup lang="ts">
import Flicking from '@egjs/vue3-flicking'
import { AutoPlay, Pagination } from '@egjs/flicking-plugins'
import '@egjs/vue3-flicking/dist/flicking.css'
import '@egjs/flicking-plugins/dist/arrow.css'
import '@egjs/flicking-plugins/dist/pagination.css'
import type { ChangedEvent } from '@egjs/vue3-flicking'
import type { AdsDatabase } from '@/types/supabaseAds'
import type { FilterDatabase } from '@/types/supabaseFilter'
import { _backgroundColor } from '#tailwind-config/theme'

type MainLargeBanner = AdsDatabase['ads']['Tables']['mainLargeBanner']['Row']
  & { adsType: FilterDatabase['filter']['Tables']['adsType']['Row'] }

const selectedIndex = defineModel('selectedIndex', {
  type: Number,
  default: 0,
})

const props = withDefaults(
  defineProps<{
    isSignIn?: boolean
    isAutoPlay?: boolean
    showPagination?: boolean
    mainLargeBannerList?: MainLargeBanner[]
  }>(),
  {
    isSignIn: false,
    isAutoPlay: true,
    showPagination: true,
    mainLargeBannerList: () => [],
  },
)

defineEmits([
  'click:main-banner',
])

const plugin = computed(() => {
  const plugins = []

  if (props.isAutoPlay) {
    plugins.push(new AutoPlay({
      animationDuration: 300,
      duration: 5000,
      direction: 'NEXT',
      stopOnHover: true,
    }))
  }

  if (props.showPagination) {
    plugins.push(new Pagination({
      type: 'fraction',
      renderFraction: (currentClass: string, totalClass: string) => {
        return `<div class="w-full flex justify-end pr-4 opacity-50">
          <div class="w-[38px] h-5 px-1.5 flex items-center justify-center gap-x-0.5 rounded-full text-xs font-light text-white bg-black">
            <span class="${currentClass}">${currentClass}</span>
            <span>/</span>
            <span class="${totalClass}">${totalClass}</span>
          </div>
        </div>`
      },
    }))
  }

  return plugins
})

const option = {
  noPanelStyleOverride: true,
  preventClickOnDrag: true,
  align: 'center',
  inputType: ['touch', 'mouse'],
  circular: true,
  circularFallback: 'bound',
  resizeOnContentsReady: true,
  deceleration: 0.004,
}

const activateDisplay = (startDate: string) => {
  return new Date() >= new Date(startDate)
}

const endDisplay = (endDate: string) => {
  return new Date() < new Date(endDate)
}
</script>

<template>
  <ClientOnly>
    <Flicking
      class="w-dvw tablet:w-[500px]"
      :hide-before-init="true"
      first-panel-size="100%"
      :plugins="plugin"
      :options="option"
      @changed="(evt: ChangedEvent) => selectedIndex = evt.index ?? 0"
    >
      <div
        v-for="(banner, index) in mainLargeBannerList"
        v-show="mainLargeBannerList && (activateDisplay(banner.start_date ?? '') && endDisplay(banner.end_date ?? ''))"
        :key="index"
        class="w-fit h-fit flex px-1"
      >
        <div
          class="w-[288px] h-[248px] mobile:w-[350px] mobile:h-[300px] flex flex-col gap-y-2 rounded-xl p-5 cursor-pointer hover:opacity-95 transition-opacity duration-200 z-10"
          :class="index === 0 ? 'ml-3' : ''"
          :style="{
            backgroundColor: banner.background_color || '#0F4843',
            color: banner.text_color || '#FDFEFF',
          }"
          @click="() => $emit('click:main-banner', banner.adsType.code ?? '', banner.link_url ?? '', banner.article_id ?? '', banner.article_category ?? '', banner.motorcycle_review_id ?? '', banner.model_id ?? '')"
        >
          <p class="text-lg mobile:text-2xl font-bold leading-6 break-keep line-clamp-2">
            {{ banner.title }}
          </p>
          <p class="text-sm font-semibold leading-5 break-keep line-clamp-2">
            {{ banner.description }}
          </p>
          <div class="flex-auto" />
          <div class="w-full flex justify-end">
            <div
              class="object-cover rounded-md"
              :class="banner.adsType.code === 'ATC006' ? 'w-full h-[98px] mobile:h-[126px]' : 'w-[144px] h-[98px] mobile:w-[182px] mobile:h-[126px] '"
            >
              <NuxtImg
                v-if="banner.thumbnail_url"
                :src="banner.thumbnail_url"
                class="w-full h-full object-cover"
                :draggable="false"
                @contextmenu.prevent
              />
            </div>
          </div>
          <p class="h-5 text-xs mobile:text-sm font-light tracking-tight">
            {{ banner?.display_date }}
          </p>
        </div>
      </div>
      <template #viewport>
        <div
          v-if="showPagination"
          class="flicking-pagination"
        />
      </template>
    </Flicking>
  </ClientOnly>
</template>

<style scoped lang="scss">
.flicking-pagination {
  position: absolute;
  cursor: pointer;
  height: fit-content;
  bottom: 16px;
  right: 16px;
  justify-items: flex-end;
}
</style>
