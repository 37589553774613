<script setup lang="ts">
withDefaults(
  defineProps<{
    preventClose?: boolean
    fullScreen?: boolean
    title?: string
    description?: string
    confirmText?: string
    cancelText?: string
    confirmColor?: string
    confirmButtonDisabled?: boolean
  }>(),
  {
    preventClose: true,
    fullScreen: false,
    title: '',
    description: '',
    confirmText: '',
    cancelText: '',
    confirmColor: '',
    confirmButtonDisabled: false,
  },
)

defineEmits([
  'confirm',
  'cancel',
])

const confirmModalTrigger = defineModel('confirmModalTrigger', {
  type: Boolean,
  default: false,
})
</script>

<template>
  <WMModal
    v-model="confirmModalTrigger"
    overlay
    :prevent-close="preventClose"
    :fullscreen="fullScreen"
    :title="title"
    dialog-title-class="text-xl font-extrabold"
    :ui="{
      container: 'flex items-center justify-center',
      width: 'w-full tablet:w-[500px]',
    }"
  >
    <div class="flex flex-col gap-y-5 py-8 px-5">
      <div class="flex flex-col gap-y-2">
        <p class="text-center text-lg font-semibold text-gray07Light dark:text-gray07Dark break-keep">
          {{ title }}
        </p>
        <p class="text-center text-sm font-semibold text-gray05Light dark:text-gray05Dark break-keep">
          {{ description }}
        </p>
      </div>
      <slot />
      <div class="w-full flex items-center gap-4">
        <AButton
          v-if="!!cancelText"
          custom-class="w-full text-lg"
          button-block
          button-size="lg"
          button-color="white"
          button-variant="outline"
          :button-text="cancelText"
          @click="() => $emit('cancel')"
        />
        <AButton
          v-if="!!confirmText"
          custom-class="w-full text-lg"
          button-block
          :button-disabled="confirmButtonDisabled"
          button-size="lg"
          button-color="green"
          button-variant="soft"
          :button-confirm-color="confirmColor"
          :button-text="confirmText"
          @click="() => $emit('confirm')"
        />
      </div>
    </div>
  </WMModal>
</template>
