<script lang="ts" setup>
import type { FilterDatabase } from '@/types/supabaseFilter'

const { url } = useImageStorage()

type HotBoardArticle = Database['public']['Views']['viewBoardArticle']['Row']
  & { profiles: Database['public']['Tables']['profiles']['Row']
    & { avatarList: Database['public']['Tables']['avatarList']['Row'] }
  }
  & { articleComment: Database['public']['Tables']['articleComment']['Row'][] }
  & { articleLike: Database['public']['Tables']['articleLike']['Row'][] }
  & { articleViewCount: Database['public']['Tables']['articleViewCount']['Row'][] }
  & { mainArticleImage: Database['public']['Tables']['articleImageList']['Row'] }

type HotMarketArticle = Database['public']['Views']['viewMarketArticle']['Row']
  & { profiles: Database['public']['Tables']['profiles']['Row']
    & { avatarList: Database['public']['Tables']['avatarList']['Row'] }
  }
  & { marketStatus: FilterDatabase['filter']['Tables']['marketStatus']['Row'] }
  & { articleComment: Database['public']['Tables']['articleComment']['Row'][] }
  & { articleLike: Database['public']['Tables']['articleLike']['Row'][] }
  & { articleViewCount: Database['public']['Tables']['articleViewCount']['Row'][] }
  & { mainArticleImage: Database['public']['Tables']['articleImageList']['Row'] }

withDefaults(
  defineProps<{
    hotestArticle?: HotBoardArticle | HotMarketArticle | null
  }>(),
  {
    hotestArticle: null,
  },
)
</script>

<template>
  <div class="w-full flex gap-x-4">
    <div class="w-[calc(100%-4.5rem)] flex flex-col gap-y-1">
      <WMBadge
        class="w-fit mb-0.5"
        variant="soft"
        color="gray"
        :label="$t(`board.${hotestArticle?.category}`) ?? ''"
        :ui="{
          variant: {
            soft: 'font-semibold text-gray05Light dark:text-gray05Dark bg-gray02Light dark:bg-gray02Dark',
          },
        }"
      />
      <p class="text-lg font-semibold text-black dark:text-white truncate">
        {{ hotestArticle?.title }}
      </p>
      <p class="text-base font-light text-gray05Light dark:text-gray05Dark truncate">
        {{ hotestArticle?.raw_content }}
      </p>
    </div>
    <NuxtImg
      v-if="hotestArticle?.mainArticleImage?.url"
      class="w-14 h-14 object-cover rounded-md ring-1 ring-gray03Light dark:ring-gray03Dark"
      :src="hotestArticle?.mainArticleImage?.url ?? ''"
      :draggable="false"
      @contextmenu.prevent
    />
    <div
      v-else
      class="w-14 h-14"
    />
  </div>
  <div class="flex flex-wrap items-center justify-between">
    <div class="flex items-center gap-x-1">
      <ACrown
        v-if="hotestArticle?.profiles.podium_point"
        :podium-point="hotestArticle?.profiles.podium_point"
        :badge-class="'w-10 py-0.5 rounded-full border-2 border-white bg-black'"
      />
      <div
        v-else
        class="w-4 h-4 flex items-center justify-center rounded-full bg-wnmPrimary"
      >
        <NuxtImg
          :src="url(true, '/assets/logo/wnm_logo_white.svg')"
          class="w-3 h-3"
        />
      </div>
      <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
        {{ hotestArticle?.profiles.nickname }}
      </span>
      <span class="text-xs font-semibold text-gray04Light dark:text-gray04Dark">
        {{ $t('text.centerDot') }}
      </span>
      <NuxtImg
        :src="url(true, '/assets/icon/message_line.svg')"
        class="w-4 h-4"
      />
      <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
        {{ hotestArticle?.articleComment ? hotestArticle?.articleComment?.length : '0' }}
      </span>
      <span class="text-xs font-semibold text-gray04Light dark:text-gray04Dark">
        {{ $t('text.centerDot') }}
      </span>
      <NuxtImg
        :src="url(true, '/assets/icon/eye_line.svg')"
        class="w-4 h-4"
      />
      <span class="text-sm font-light text-gray05Light dark:text-gray05Dark">
        {{ hotestArticle?.articleViewCount ? hotestArticle?.articleViewCount?.length : '0' }}
      </span>
    </div>
    <ANuxtTime
      class="text-sm font-light text-gray05Light dark:text-gray05Dark"
      :date-time="hotestArticle?.created_at"
      :capital-date-time="false"
      :full-date-time="false"
    />
  </div>
</template>
