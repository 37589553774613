<script setup lang="ts">
const user = useSupabaseUser()
const client = useSupabaseClient()
const colorMode = useColorMode()

const { url } = useImageStorage()

const {
  integratedNotification,
  newOwnerMeetingAlarm,
  newCoachingMeetingAlarm,
  newRepairMeetingAlarm,
  newRescueMeetingAlarm,
  newPickupMeetingAlarm,
  newMeetingFeedbackAlarm,
  newGuestbookAlarm,
  newReceiveSparkAlarm,
  newReceivePointAlarm,
  newArticleCommentAlarm,
} = storeToRefs(useNotificationsStore())

defineEmits([
  'click:menu-open',
])

const listenOwnerMeeting = ref()
const listenCoachingMeeting = ref()
const listenRepairMeeting = ref()
const listenRescueMeeting = ref()
const listenPickupMeeting = ref()

const listenFeedback = ref()
const listenGuestbook = ref()
const listenSparkList = ref()
const listenPointList = ref()
const listenArticleComment = ref()

const subscribeToMeeting = (channelName: string, meetingType: 'MTC001' | 'MTC002' | 'MTC003' | 'MTC004' | 'MTC005') => {
  return client
    .channel(channelName)
    .on('postgres_changes',
      { event: 'INSERT', schema: 'chat_channel', table: channelName, filter: `host_user_id=eq.${user.value?.id ?? ''}` },
      (payload: any) => {
        if (payload.new.new_expert_message || payload.new.new_host_message) {
          updateNotificationStatus(meetingType)
        }
      },
    )
    .on('postgres_changes',
      { event: 'INSERT', schema: 'chat_channel', table: channelName, filter: `expert_user_id=eq.${user.value?.id ?? ''}` },
      (payload: any) => {
        if (payload.new.new_host_message || payload.new.new_expert_message) {
          updateNotificationStatus(meetingType)
        }
      },
    )
    .on('postgres_changes',
      { event: 'UPDATE', schema: 'chat_channel', table: channelName, filter: `host_user_id=eq.${user.value?.id ?? ''}` },
      (payload: any) => {
        if (payload.new.new_expert_message || payload.new.new_host_message) {
          updateNotificationStatus(meetingType)
        }
      },
    )
    .on('postgres_changes',
      { event: 'UPDATE', schema: 'chat_channel', table: channelName, filter: `expert_user_id=eq.${user.value?.id ?? ''}` },
      (payload: any) => {
        if (payload.new.new_host_message || payload.new.new_expert_message) {
          updateNotificationStatus(meetingType)
        }
      },
    )
    .subscribe()
}

const subscribeToFeedback = () => {
  return client
    .channel('meetingFeedback')
    .on('postgres_changes',
      { event: 'INSERT', schema: 'chat_channel', table: 'meetingFeedback', filter: `expert_user_id=eq.${user.value?.id ?? ''}` },
      () => {
        newMeetingFeedbackAlarm.value = true
      },
    )
    .subscribe()
}

const subscribeToPublicChannel = (channelName: string) => {
  const filterData = (() => {
    switch (channelName) {
      case 'guestbook':
        return `owner_user_id=eq.${user.value?.id ?? ''}`
      case 'sparkList':
        return `review_owner_user_id=eq.${user.value?.id ?? ''}`
      case 'pointList':
        return `update_user_id=eq.${user.value?.id ?? ''}`
      case 'articleComment':
        return `article_owner_user_id=eq.${user.value?.id ?? ''}`
    }
  })()

  return client
    .channel(channelName)
    .on('postgres_changes',
      { event: 'INSERT', schema: 'public', table: channelName, filter: filterData },
      () => {
        switch (channelName) {
          case 'guestbook':
            newGuestbookAlarm.value = true
            break
          case 'sparkList':
            newReceiveSparkAlarm.value = true
            break
          case 'pointList':
            newReceivePointAlarm.value = true
            break
          case 'articleComment':
            newArticleCommentAlarm.value = true
            break
        }
      },
    )
    .subscribe()
}

const updateNotificationStatus = (meetingType: 'MTC001' | 'MTC002' | 'MTC003' | 'MTC004' | 'MTC005') => {
  switch (meetingType) {
    case 'MTC001':
      newOwnerMeetingAlarm.value = true
      break
    case 'MTC002':
      newCoachingMeetingAlarm.value = true
      break
    case 'MTC003':
      newRepairMeetingAlarm.value = true
      break
    case 'MTC004':
      newRescueMeetingAlarm.value = true
      break
    case 'MTC005':
      newPickupMeetingAlarm.value = true
      break
  }
}

const computedNewMeetingAlarm = computed(() => {
  return newOwnerMeetingAlarm.value
    || newCoachingMeetingAlarm.value
    || newRepairMeetingAlarm.value
    || newRescueMeetingAlarm.value
    || newPickupMeetingAlarm.value
})

const updateIntegratedNotification = () => {
  if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.updateIntegratedNotification) {
    window.webkit.messageHandlers.updateIntegratedNotification.postMessage({
      notificationKey: 'integratedNotification',
      notificationValue: integratedNotification.value ?? false,
    })
  }
}

if (user.value?.id) {
  listenOwnerMeeting.value = subscribeToMeeting('ownerMeeting', 'MTC001')
  listenCoachingMeeting.value = subscribeToMeeting('coachingMeeting', 'MTC002')
  listenRepairMeeting.value = subscribeToMeeting('repairMeeting', 'MTC003')
  listenRescueMeeting.value = subscribeToMeeting('rescueMeeting', 'MTC004')
  listenPickupMeeting.value = subscribeToMeeting('pickupMeeting', 'MTC005')

  listenFeedback.value = subscribeToFeedback()
  listenGuestbook.value = subscribeToPublicChannel('guestbook')
  listenSparkList.value = subscribeToPublicChannel('sparkList')
  listenPointList.value = subscribeToPublicChannel('pointList')
  listenArticleComment.value = subscribeToPublicChannel('articleComment')
}

onUnmounted(() => {
  if (user.value?.id) {
    listenOwnerMeeting.value.unsubscribe()
    listenCoachingMeeting.value.unsubscribe()
    listenRepairMeeting.value.unsubscribe()
    listenRescueMeeting.value.unsubscribe()
    listenPickupMeeting.value.unsubscribe()

    listenFeedback.value.unsubscribe()
    listenGuestbook.value.unsubscribe()
    listenSparkList.value.unsubscribe()
    listenPointList.value.unsubscribe()
    listenArticleComment.value.unsubscribe()
  }
})

watchEffect(() => {
  integratedNotification.value = computedNewMeetingAlarm.value
  || newMeetingFeedbackAlarm.value
  || newGuestbookAlarm.value
  || newReceiveSparkAlarm.value
  || newReceivePointAlarm.value
  || newArticleCommentAlarm.value

  updateIntegratedNotification()
})
</script>

<template>
  <div class="h-fit flex items-center gap-4 mt-5 px-5 pb-3 border-b border-gray03Light dark:border-gray03Dark">
    <AButton
      custom-class="p-0"
      button-variant="ghost"
      button-color="black"
      use-leading
      :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/menu.svg' : '/assets/icon/menu_dark.svg')"
      :image-size="24"
      @click="() => $emit('click:menu-open')"
    />
    <NuxtImg
      class="cursor-pointer"
      :src="url(true, '/assets/logo/wnm_logo.svg')"
      :width="32"
      @click="navigateTo('/')"
    />
    <div class="flex-auto" />
    <AChipButton
      v-if="user?.id"
      button-custom-class="p-0"
      :enable-chip="integratedNotification"
      :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/alarm.svg' : '/assets/icon/alarm_dark.svg')"
      @click:chip="navigateTo('/my/alarm')"
    />
    <AButton
      v-if="user?.id"
      custom-class="p-0"
      button-variant="ghost"
      button-color="black"
      use-leading
      :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/search.svg' : '/assets/icon/search_dark.svg')"
      :image-size="24"
      @click="() => navigateTo('/search')"
    />
  </div>
</template>
