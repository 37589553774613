<script setup lang="ts">
const menuOpenTrigger = ref(false)
</script>

<template>
  <div class="w-full h-fit sticky top-0 bg-gray01Light dark:bg-gray01Dark z-10">
    <HeaderNavigationBar @click:menu-open="menuOpenTrigger = !menuOpenTrigger" />
    <HeaderMenu v-model:menu-open-trigger="menuOpenTrigger" />
  </div>
</template>
