import { defineStore } from 'pinia'

export const useNotificationsStore = defineStore('useNotificationsStore', () => {
  /**
   * ! Pinia State !
   *
   * @param integratedNotification 통합 알림
   * @param newOwnerMeetingAlarm 오너 미팅 알림
   * @param newCoachingMeetingAlarm 주행 코칭 미팅 알림
   * @param newRepairMeetingAlarm 정비 미팅 알림
   * @param newRescueMeetingAlarm 구난 미팅 알림
   * @param newPickupMeetingAlarm 픽업 미팅 알림
   *
   * @param newMeetingFeedbackAlarm 미팅 후기 알림
   * @param newGuestbookAlarm 방명록 알림
   * @param newReceiveSparkAlarm 받은 스파크 알림
   * @param newReceivePointAlarm 받은 포인트 알림
   * @param newArticleCommentAlarm 게시물 댓글 알림
   *
   */

  const integratedNotification = ref(false)

  const newOwnerMeetingAlarm = ref(false)
  const newCoachingMeetingAlarm = ref(false)
  const newRepairMeetingAlarm = ref(false)
  const newRescueMeetingAlarm = ref(false)
  const newPickupMeetingAlarm = ref(false)

  const newMeetingFeedbackAlarm = ref(false)
  const newGuestbookAlarm = ref(false)
  const newReceiveSparkAlarm = ref(false)
  const newReceivePointAlarm = ref(false)
  const newArticleCommentAlarm = ref(false)

  return {
    integratedNotification,
    newOwnerMeetingAlarm,
    newCoachingMeetingAlarm,
    newRepairMeetingAlarm,
    newRescueMeetingAlarm,
    newPickupMeetingAlarm,
    newMeetingFeedbackAlarm,
    newGuestbookAlarm,
    newReceiveSparkAlarm,
    newReceivePointAlarm,
    newArticleCommentAlarm,
  }
}, {
  persist: {
    storage: piniaPluginPersistedstate.sessionStorage(),
  },
})
