<script lang="ts" setup>
const { url } = useImageStorage()

withDefaults(
  defineProps<{
    badgeClass?: string
    crownClass?: string
    podiumPoint?: number
  }>(),
  {
    badgeClass: '',
    crownClass: 'w-5 h-5',
    podiumPoint: 0,
  },
)
</script>

<template>
  <div
    class="flex items-center"
    :class="badgeClass"
  >
    <NuxtImg
      :src="url(true, '/assets/icon/crown_small.svg')"
      :class="crownClass"
    />
    <span class="text-xs text-accent02YellowLight dark:text-accent02YellowDark">
      {{ $t('text.podium', { count: podiumPoint }) }}
    </span>
  </div>
</template>
