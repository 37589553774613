<script setup lang="ts">
const { t } = useLocale()
const { meta } = useRoute()
const { coords, resume } = useGeolocation()

const userLocation = useUserLocation()

const { refreshMenuData } = useLoadMenuData()
const { executeFilterData } = useLoadFilterData()
const { executeUserData } = useLoadUserData()

const { refreshKatechCoords } = userLocation
const { latitude, longitude } = storeToRefs(userLocation)

const seoTitle = 'WNM'
const seoDescription = '나의 이륜차 경험을 공유하고 소통하는 공간입니다.'
const seoUrl = 'https://moto.wnm.zone'
const seoImage = 'https://moto.wnm.zone/image/og_image.jpg'

useHead({
  title: (meta.title as string) ?? t('pageTitle.wnm'),
  titleTemplate: (title?: string) => {
    let returnTitle = title

    returnTitle = title && !title?.includes('-')
      ? t('pageTitle.wnm').concat(' | ', title)
      : ''

    return returnTitle
  },
  meta: () => [
    { name: 'msapplication-TileColor', content: '#19786F' },
    { name: 'theme-color', content: '#19786F' },
    { property: 'Imagetoolbar', content: 'no' },
    { name: 'naver-site-verification', content: '45221b0b173c5c46a69b9f284b6dc49847376e84' },
    { name: 'google-site-verification', content: 'CudV-wxEYmfyEDzIdGdcOVGPyMxorvepJbA5X-qMzKA' },
  ],
  link: [
    { rel: 'canonical', href: seoUrl },
    { rel: 'icon', type: 'image/png', href: '/image/favicon-96x96.png' },
    { rel: 'icon', type: 'image/svg+xml', href: '/image/favicon.svg' },
    { rel: 'shortcut icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', sizes: '180x180', href: '/image/apple-touch-icon.png' },
    { rel: 'manifest', href: '/manifest.webmanifest' },
  ],
  script: [
    {
      hid: 'NaverIdLogin',
      src: 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.2.js',
      defer: true,
    },
    {
      hid: 'KakaoMaps',
      src: `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.KAKAO_JAVASCRIPT_API_KEY}&libraries=services`,
      type: 'text/javascript',
    },
    {
      hid: 'KakaoApis',
      src: 'https://t1.kakaocdn.net/kakao_js_sdk/2.7.2/kakao.min.js',
      type: 'text/javascript',
    },
    {
      hid: 'NiceForStart',
      src: 'https://pay.nicepay.co.kr/v1/js/',
      type: 'text/javascript',
    },
  ],
})

useSeoMeta({
  charset: 'utf-16',
  viewport: 'width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=0',
  formatDetection: 'telephone=no',
  themeColor: '#19786F',
  keywords: 'wheelsmeets,모터사이클,오토바이,할리데이비슨,바이크,motorcycle,harleydavidson,harley,honda,혼다,ducati,두카티,bmw,비엠더블유,ktm,케이티엠,yamaha,야마하,royalenfield,로얄엔필드,custom,커스텀,커스텀바이크,custombike,custombikes,커스텀바이크',
  title: seoTitle,
  author: 'goodNodes',
  description: seoDescription,
  ogType: 'website',
  ogTitle: seoTitle,
  ogDescription: seoDescription,
  ogUrl: seoUrl,
  ogImage: seoImage,
  ogImageSecureUrl: seoImage,
  ogImageType: 'image/png',
  ogImageWidth: '410',
  ogImageHeight: '200',
  twitterCard: 'summary_large_image',
  twitterTitle: seoTitle,
  twitterDescription: seoDescription,
  twitterImage: seoImage,
})

watchEffect(() => {
  if (coords.value.latitude === Infinity) {
    resume()
    return
  }

  if (latitude.value === coords.value.latitude && longitude.value === coords.value.longitude) {
    return
  }

  latitude.value = coords.value.latitude
  longitude.value = coords.value.longitude
  refreshKatechCoords()
})

await refreshMenuData()
await executeUserData()

executeFilterData()
</script>

<template>
  <div>
    <NuxtLayout>
      <div>
        <NuxtLoadingIndicator
          color="repeating-linear-gradient(to right,#FDFEFF 0%,#19786F 100%)"
          :height="5"
        />
        <NuxtPage />
        <WMNotifications :ui="{ wrapper: 'w-full tablet:w-96 pointer-events-auto' }" />
      </div>
    </NuxtLayout>
  </div>
</template>
