import { default as _91boardId_93Vd3t7Uv3uLMeta } from "/vercel/path0/pages/announce/[tab]/[boardId].vue?macro=true";
import { default as indextdPLPajYMbMeta } from "/vercel/path0/pages/announce/index.vue?macro=true";
import { default as _91boardId_93YnKLkSwBI6Meta } from "/vercel/path0/pages/board/business/[boardId].vue?macro=true";
import { default as index8xWoeNaSyqMeta } from "/vercel/path0/pages/board/business/index.vue?macro=true";
import { default as _91boardId_93Zbh8SCq4z1Meta } from "/vercel/path0/pages/board/free/[boardId].vue?macro=true";
import { default as indexSpkc2suOekMeta } from "/vercel/path0/pages/board/free/index.vue?macro=true";
import { default as _91boardId_934x63qcN1tQMeta } from "/vercel/path0/pages/board/gallery/[boardId].vue?macro=true";
import { default as indexykswO14JE6Meta } from "/vercel/path0/pages/board/gallery/index.vue?macro=true";
import { default as _91boardId_93tPpCZ2cbxXMeta } from "/vercel/path0/pages/board/guestbook/[boardId].vue?macro=true";
import { default as indexz1yQ33v7KBMeta } from "/vercel/path0/pages/board/guestbook/index.vue?macro=true";
import { default as writegCG1iuGk4PMeta } from "/vercel/path0/pages/board/guestbook/write.vue?macro=true";
import { default as _91boardId_93Sbawm7t3PPMeta } from "/vercel/path0/pages/board/issue/[boardId].vue?macro=true";
import { default as indexLuwn8YrrqlMeta } from "/vercel/path0/pages/board/issue/index.vue?macro=true";
import { default as _91boardId_93CIWgHeUyONMeta } from "/vercel/path0/pages/board/qna/[boardId].vue?macro=true";
import { default as indexOtawThPafWMeta } from "/vercel/path0/pages/board/qna/index.vue?macro=true";
import { default as _91boardId_9313lHlbDlsVMeta } from "/vercel/path0/pages/board/reportTheft/[boardId].vue?macro=true";
import { default as indexPiTRaXwbi3Meta } from "/vercel/path0/pages/board/reportTheft/index.vue?macro=true";
import { default as _91boardId_93CiXmAoAyC8Meta } from "/vercel/path0/pages/board/riding/[boardId].vue?macro=true";
import { default as indexkwXt8Q7qMBMeta } from "/vercel/path0/pages/board/riding/index.vue?macro=true";
import { default as _91boardId_93LJUc83gcshMeta } from "/vercel/path0/pages/board/route/[boardId].vue?macro=true";
import { default as indexts7QUKBy0CMeta } from "/vercel/path0/pages/board/route/index.vue?macro=true";
import { default as _91boardId_9354dtn1dBTMMeta } from "/vercel/path0/pages/board/tuning/[boardId].vue?macro=true";
import { default as index1PaMa4TDmmMeta } from "/vercel/path0/pages/board/tuning/index.vue?macro=true";
import { default as _91boardId_93hvI4XjtN5QMeta } from "/vercel/path0/pages/board/VehicleCare/[boardId].vue?macro=true";
import { default as indexmo7y2TrjIsMeta } from "/vercel/path0/pages/board/VehicleCare/index.vue?macro=true";
import { default as write0wRnOAkWGiMeta } from "/vercel/path0/pages/board/write.vue?macro=true";
import { default as confirm2uAIxw9fKZMeta } from "/vercel/path0/pages/confirm.vue?macro=true";
import { default as faqM4LN7jxnMuMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as naverL2J3UqldP3Meta } from "/vercel/path0/pages/login/naver.vue?macro=true";
import { default as newfEOIiDZ51GMeta } from "/vercel/path0/pages/login/new.vue?macro=true";
import { default as welcomeXFKkW4B3tOMeta } from "/vercel/path0/pages/login/welcome.vue?macro=true";
import { default as withdrawaluIUz3DJFjlMeta } from "/vercel/path0/pages/login/withdrawal.vue?macro=true";
import { default as _91boardId_93KK7blUHZo9Meta } from "/vercel/path0/pages/market/buy/[boardId].vue?macro=true";
import { default as indexCYU215wYXXMeta } from "/vercel/path0/pages/market/buy/index.vue?macro=true";
import { default as _91boardId_93A8kckpCPaMMeta } from "/vercel/path0/pages/market/deliveryGear/[boardId].vue?macro=true";
import { default as index1uwG24ztYtMeta } from "/vercel/path0/pages/market/deliveryGear/index.vue?macro=true";
import { default as _91boardId_93u5hGdW2dsWMeta } from "/vercel/path0/pages/market/gear/[boardId].vue?macro=true";
import { default as indexsqgFvU7XprMeta } from "/vercel/path0/pages/market/gear/index.vue?macro=true";
import { default as _91boardId_93mhzQ3DH1miMeta } from "/vercel/path0/pages/market/over125Manual/[boardId].vue?macro=true";
import { default as indexLLF8M7pFYVMeta } from "/vercel/path0/pages/market/over125Manual/index.vue?macro=true";
import { default as _91boardId_93KhZUGE8YAyMeta } from "/vercel/path0/pages/market/over125Scooter/[boardId].vue?macro=true";
import { default as indexuR1HFNJlq7Meta } from "/vercel/path0/pages/market/over125Scooter/index.vue?macro=true";
import { default as _91boardId_93tjH31sEAlBMeta } from "/vercel/path0/pages/market/parts/[boardId].vue?macro=true";
import { default as indexkKa5BWpBG5Meta } from "/vercel/path0/pages/market/parts/index.vue?macro=true";
import { default as _91boardId_93MPDdkw6XskMeta } from "/vercel/path0/pages/market/under125Manual/[boardId].vue?macro=true";
import { default as indextUymX6AYVuMeta } from "/vercel/path0/pages/market/under125Manual/index.vue?macro=true";
import { default as _91boardId_93WYjeNI5tnoMeta } from "/vercel/path0/pages/market/under125Scooter/[boardId].vue?macro=true";
import { default as indexhHZuoUtzdpMeta } from "/vercel/path0/pages/market/under125Scooter/index.vue?macro=true";
import { default as writeSHSMjLfpRFMeta } from "/vercel/path0/pages/market/write.vue?macro=true";
import { default as chat6yquTnr4JaMeta } from "/vercel/path0/pages/meeting/coaching/chat.vue?macro=true";
import { default as indexP8dK97GLuPMeta } from "/vercel/path0/pages/meeting/coaching/index.vue?macro=true";
import { default as searchX4z6bkkpFnMeta } from "/vercel/path0/pages/meeting/coaching/search.vue?macro=true";
import { default as feedbackuEO4omixEWMeta } from "/vercel/path0/pages/meeting/feedback.vue?macro=true";
import { default as chatkYC5HtyZQLMeta } from "/vercel/path0/pages/meeting/owner/chat.vue?macro=true";
import { default as indexCTCKT4SMw2Meta } from "/vercel/path0/pages/meeting/owner/index.vue?macro=true";
import { default as searchFsagdMY29gMeta } from "/vercel/path0/pages/meeting/owner/search.vue?macro=true";
import { default as chatMyaYDPSiuhMeta } from "/vercel/path0/pages/meeting/pickup/chat.vue?macro=true";
import { default as index4DJddCeFO9Meta } from "/vercel/path0/pages/meeting/pickup/index.vue?macro=true";
import { default as searchqLD9uWdcxfMeta } from "/vercel/path0/pages/meeting/pickup/search.vue?macro=true";
import { default as chatAoVDUoKiTjMeta } from "/vercel/path0/pages/meeting/repair/chat.vue?macro=true";
import { default as indexCCOG9PC5XJMeta } from "/vercel/path0/pages/meeting/repair/index.vue?macro=true";
import { default as searchdVyRbngRiBMeta } from "/vercel/path0/pages/meeting/repair/search.vue?macro=true";
import { default as chatv0qszGZsYCMeta } from "/vercel/path0/pages/meeting/rescue/chat.vue?macro=true";
import { default as indexxNpEcTnNieMeta } from "/vercel/path0/pages/meeting/rescue/index.vue?macro=true";
import { default as searchK8o0dcnPmCMeta } from "/vercel/path0/pages/meeting/rescue/search.vue?macro=true";
import { default as alarmvqOuuFICjvMeta } from "/vercel/path0/pages/my/alarm.vue?macro=true";
import { default as boardQKNegkj13WMeta } from "/vercel/path0/pages/my/board.vue?macro=true";
import { default as comment64VhN4jLx4Meta } from "/vercel/path0/pages/my/comment.vue?macro=true";
import { default as couponXU7chmpesfMeta } from "/vercel/path0/pages/my/coupon.vue?macro=true";
import { default as feedbacklhL9Arpl7XMeta } from "/vercel/path0/pages/my/feedback.vue?macro=true";
import { default as keepLSSxRlFwPtMeta } from "/vercel/path0/pages/my/keep.vue?macro=true";
import { default as marketiEHQic5HgzMeta } from "/vercel/path0/pages/my/market.vue?macro=true";
import { default as meetingVRkZUcgqNdMeta } from "/vercel/path0/pages/my/meeting.vue?macro=true";
import { default as pintiF2aZxUWFMeta } from "/vercel/path0/pages/my/pin.vue?macro=true";
import { default as pointRQRTOh63KnMeta } from "/vercel/path0/pages/my/point.vue?macro=true";
import { default as repairSkill7TGRfj1IgXMeta } from "/vercel/path0/pages/my/repairSkill.vue?macro=true";
import { default as reviewSXOfnxCu0SMeta } from "/vercel/path0/pages/my/review.vue?macro=true";
import { default as shop4YM4EX21epMeta } from "/vercel/path0/pages/my/shop.vue?macro=true";
import { default as sparkEFRjFlPl8wMeta } from "/vercel/path0/pages/my/spark.vue?macro=true";
import { default as indexTItJgGVEb6Meta } from "/vercel/path0/pages/point/index.vue?macro=true";
import { default as boardRC1Fql1ogSMeta } from "/vercel/path0/pages/policy/board.vue?macro=true";
import { default as indexvrx28GESt5Meta } from "/vercel/path0/pages/policy/index.vue?macro=true";
import { default as marketr1PeqTKQ6OMeta } from "/vercel/path0/pages/policy/market.vue?macro=true";
import { default as privacyRYXZGN4huRMeta } from "/vercel/path0/pages/policy/privacy.vue?macro=true";
import { default as termsicxjzeK7QGMeta } from "/vercel/path0/pages/policy/terms.vue?macro=true";
import { default as _91id_93FDV9aaSSaFMeta } from "/vercel/path0/pages/profile/[id].vue?macro=true";
import { default as indexSsrwCXeuTQMeta } from "/vercel/path0/pages/profile/index.vue?macro=true";
import { default as indexftjGK2FLAwMeta } from "/vercel/path0/pages/report/motorcycle/index.vue?macro=true";
import { default as indexe7OizcFKr4Meta } from "/vercel/path0/pages/review/gear/index.vue?macro=true";
import { default as indexHxa16JqNIHMeta } from "/vercel/path0/pages/review/motorcycle/index.vue?macro=true";
import { default as lista1EowrQeioMeta } from "/vercel/path0/pages/review/motorcycle/list.vue?macro=true";
import { default as index0YrmoYIrhWMeta } from "/vercel/path0/pages/review/motorcycle/my/index.vue?macro=true";
import { default as indexpmpJC424D3Meta } from "/vercel/path0/pages/review/motorcycle/search/index.vue?macro=true";
import { default as resultwNB5qWDY1mMeta } from "/vercel/path0/pages/review/motorcycle/search/result.vue?macro=true";
import { default as confirmaSlWTOBqOFMeta } from "/vercel/path0/pages/review/motorcycle/write/confirm.vue?macro=true";
import { default as index4h8Z3bqcfdMeta } from "/vercel/path0/pages/review/motorcycle/write/index.vue?macro=true";
import { default as initSonDV8mg06Meta } from "/vercel/path0/pages/review/motorcycle/write/init.vue?macro=true";
import { default as search4N3qS51VkgMeta } from "/vercel/path0/pages/review/motorcycle/write/search.vue?macro=true";
import { default as indexDqV3Lxa5AvMeta } from "/vercel/path0/pages/search/index.vue?macro=true";
import { default as resultENSgNekA1BMeta } from "/vercel/path0/pages/search/result.vue?macro=true";
import { default as blindeTK1umDDBVMeta } from "/vercel/path0/pages/setting/blind.vue?macro=true";
import { default as indexlj5aTopGWCMeta } from "/vercel/path0/pages/setting/index.vue?macro=true";
import { default as securityaSOaHHrXJVMeta } from "/vercel/path0/pages/setting/security.vue?macro=true";
import { default as indexoSSFurIOMXMeta } from "/vercel/path0/pages/settlement/index.vue?macro=true";
import { default as securityCheckfeazJA5A8AMeta } from "/vercel/path0/pages/settlement/securityCheck.vue?macro=true";
import { default as resultbyvPGhATCAMeta } from "/vercel/path0/pages/specCompare/result.vue?macro=true";
import { default as searchjvFp7iT6hDMeta } from "/vercel/path0/pages/specCompare/search.vue?macro=true";
import { default as selectq6KnhDY9OAMeta } from "/vercel/path0/pages/specCompare/select.vue?macro=true";
export default [
  {
    name: "announce-tab-boardId",
    path: "/announce/:tab()/:boardId()",
    component: () => import("/vercel/path0/pages/announce/[tab]/[boardId].vue")
  },
  {
    name: "announce",
    path: "/announce",
    component: () => import("/vercel/path0/pages/announce/index.vue")
  },
  {
    name: "board-business-boardId",
    path: "/board/business/:boardId()",
    meta: _91boardId_93YnKLkSwBI6Meta || {},
    component: () => import("/vercel/path0/pages/board/business/[boardId].vue")
  },
  {
    name: "board-business",
    path: "/board/business",
    component: () => import("/vercel/path0/pages/board/business/index.vue")
  },
  {
    name: "board-free-boardId",
    path: "/board/free/:boardId()",
    meta: _91boardId_93Zbh8SCq4z1Meta || {},
    component: () => import("/vercel/path0/pages/board/free/[boardId].vue")
  },
  {
    name: "board-free",
    path: "/board/free",
    component: () => import("/vercel/path0/pages/board/free/index.vue")
  },
  {
    name: "board-gallery-boardId",
    path: "/board/gallery/:boardId()",
    meta: _91boardId_934x63qcN1tQMeta || {},
    component: () => import("/vercel/path0/pages/board/gallery/[boardId].vue")
  },
  {
    name: "board-gallery",
    path: "/board/gallery",
    component: () => import("/vercel/path0/pages/board/gallery/index.vue")
  },
  {
    name: "board-guestbook-boardId",
    path: "/board/guestbook/:boardId()",
    meta: _91boardId_93tPpCZ2cbxXMeta || {},
    component: () => import("/vercel/path0/pages/board/guestbook/[boardId].vue")
  },
  {
    name: "board-guestbook",
    path: "/board/guestbook",
    meta: indexz1yQ33v7KBMeta || {},
    component: () => import("/vercel/path0/pages/board/guestbook/index.vue")
  },
  {
    name: "board-guestbook-write",
    path: "/board/guestbook/write",
    meta: writegCG1iuGk4PMeta || {},
    component: () => import("/vercel/path0/pages/board/guestbook/write.vue")
  },
  {
    name: "board-issue-boardId",
    path: "/board/issue/:boardId()",
    meta: _91boardId_93Sbawm7t3PPMeta || {},
    component: () => import("/vercel/path0/pages/board/issue/[boardId].vue")
  },
  {
    name: "board-issue",
    path: "/board/issue",
    component: () => import("/vercel/path0/pages/board/issue/index.vue")
  },
  {
    name: "board-qna-boardId",
    path: "/board/qna/:boardId()",
    meta: _91boardId_93CIWgHeUyONMeta || {},
    component: () => import("/vercel/path0/pages/board/qna/[boardId].vue")
  },
  {
    name: "board-qna",
    path: "/board/qna",
    component: () => import("/vercel/path0/pages/board/qna/index.vue")
  },
  {
    name: "board-reportTheft-boardId",
    path: "/board/reportTheft/:boardId()",
    meta: _91boardId_9313lHlbDlsVMeta || {},
    component: () => import("/vercel/path0/pages/board/reportTheft/[boardId].vue")
  },
  {
    name: "board-reportTheft",
    path: "/board/reportTheft",
    component: () => import("/vercel/path0/pages/board/reportTheft/index.vue")
  },
  {
    name: "board-riding-boardId",
    path: "/board/riding/:boardId()",
    meta: _91boardId_93CiXmAoAyC8Meta || {},
    component: () => import("/vercel/path0/pages/board/riding/[boardId].vue")
  },
  {
    name: "board-riding",
    path: "/board/riding",
    component: () => import("/vercel/path0/pages/board/riding/index.vue")
  },
  {
    name: "board-route-boardId",
    path: "/board/route/:boardId()",
    meta: _91boardId_93LJUc83gcshMeta || {},
    component: () => import("/vercel/path0/pages/board/route/[boardId].vue")
  },
  {
    name: "board-route",
    path: "/board/route",
    component: () => import("/vercel/path0/pages/board/route/index.vue")
  },
  {
    name: "board-tuning-boardId",
    path: "/board/tuning/:boardId()",
    meta: _91boardId_9354dtn1dBTMMeta || {},
    component: () => import("/vercel/path0/pages/board/tuning/[boardId].vue")
  },
  {
    name: "board-tuning",
    path: "/board/tuning",
    component: () => import("/vercel/path0/pages/board/tuning/index.vue")
  },
  {
    name: "board-VehicleCare-boardId",
    path: "/board/VehicleCare/:boardId()",
    meta: _91boardId_93hvI4XjtN5QMeta || {},
    component: () => import("/vercel/path0/pages/board/VehicleCare/[boardId].vue")
  },
  {
    name: "board-VehicleCare",
    path: "/board/VehicleCare",
    component: () => import("/vercel/path0/pages/board/VehicleCare/index.vue")
  },
  {
    name: "board-write",
    path: "/board/write",
    meta: write0wRnOAkWGiMeta || {},
    component: () => import("/vercel/path0/pages/board/write.vue")
  },
  {
    name: "confirm",
    path: "/confirm",
    meta: confirm2uAIxw9fKZMeta || {},
    component: () => import("/vercel/path0/pages/confirm.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexe9Brt5DfdhMeta || {},
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/pages/login/index.vue")
  },
  {
    name: "login-naver",
    path: "/login/naver",
    component: () => import("/vercel/path0/pages/login/naver.vue")
  },
  {
    name: "login-new",
    path: "/login/new",
    meta: newfEOIiDZ51GMeta || {},
    component: () => import("/vercel/path0/pages/login/new.vue")
  },
  {
    name: "login-welcome",
    path: "/login/welcome",
    meta: welcomeXFKkW4B3tOMeta || {},
    component: () => import("/vercel/path0/pages/login/welcome.vue")
  },
  {
    name: "login-withdrawal",
    path: "/login/withdrawal",
    component: () => import("/vercel/path0/pages/login/withdrawal.vue")
  },
  {
    name: "market-buy-boardId",
    path: "/market/buy/:boardId()",
    meta: _91boardId_93KK7blUHZo9Meta || {},
    component: () => import("/vercel/path0/pages/market/buy/[boardId].vue")
  },
  {
    name: "market-buy",
    path: "/market/buy",
    component: () => import("/vercel/path0/pages/market/buy/index.vue")
  },
  {
    name: "market-deliveryGear-boardId",
    path: "/market/deliveryGear/:boardId()",
    meta: _91boardId_93A8kckpCPaMMeta || {},
    component: () => import("/vercel/path0/pages/market/deliveryGear/[boardId].vue")
  },
  {
    name: "market-deliveryGear",
    path: "/market/deliveryGear",
    component: () => import("/vercel/path0/pages/market/deliveryGear/index.vue")
  },
  {
    name: "market-gear-boardId",
    path: "/market/gear/:boardId()",
    meta: _91boardId_93u5hGdW2dsWMeta || {},
    component: () => import("/vercel/path0/pages/market/gear/[boardId].vue")
  },
  {
    name: "market-gear",
    path: "/market/gear",
    component: () => import("/vercel/path0/pages/market/gear/index.vue")
  },
  {
    name: "market-over125Manual-boardId",
    path: "/market/over125Manual/:boardId()",
    meta: _91boardId_93mhzQ3DH1miMeta || {},
    component: () => import("/vercel/path0/pages/market/over125Manual/[boardId].vue")
  },
  {
    name: "market-over125Manual",
    path: "/market/over125Manual",
    component: () => import("/vercel/path0/pages/market/over125Manual/index.vue")
  },
  {
    name: "market-over125Scooter-boardId",
    path: "/market/over125Scooter/:boardId()",
    meta: _91boardId_93KhZUGE8YAyMeta || {},
    component: () => import("/vercel/path0/pages/market/over125Scooter/[boardId].vue")
  },
  {
    name: "market-over125Scooter",
    path: "/market/over125Scooter",
    component: () => import("/vercel/path0/pages/market/over125Scooter/index.vue")
  },
  {
    name: "market-parts-boardId",
    path: "/market/parts/:boardId()",
    meta: _91boardId_93tjH31sEAlBMeta || {},
    component: () => import("/vercel/path0/pages/market/parts/[boardId].vue")
  },
  {
    name: "market-parts",
    path: "/market/parts",
    component: () => import("/vercel/path0/pages/market/parts/index.vue")
  },
  {
    name: "market-under125Manual-boardId",
    path: "/market/under125Manual/:boardId()",
    meta: _91boardId_93MPDdkw6XskMeta || {},
    component: () => import("/vercel/path0/pages/market/under125Manual/[boardId].vue")
  },
  {
    name: "market-under125Manual",
    path: "/market/under125Manual",
    component: () => import("/vercel/path0/pages/market/under125Manual/index.vue")
  },
  {
    name: "market-under125Scooter-boardId",
    path: "/market/under125Scooter/:boardId()",
    meta: _91boardId_93WYjeNI5tnoMeta || {},
    component: () => import("/vercel/path0/pages/market/under125Scooter/[boardId].vue")
  },
  {
    name: "market-under125Scooter",
    path: "/market/under125Scooter",
    component: () => import("/vercel/path0/pages/market/under125Scooter/index.vue")
  },
  {
    name: "market-write",
    path: "/market/write",
    meta: writeSHSMjLfpRFMeta || {},
    component: () => import("/vercel/path0/pages/market/write.vue")
  },
  {
    name: "meeting-coaching-chat",
    path: "/meeting/coaching/chat",
    meta: chat6yquTnr4JaMeta || {},
    component: () => import("/vercel/path0/pages/meeting/coaching/chat.vue")
  },
  {
    name: "meeting-coaching",
    path: "/meeting/coaching",
    meta: indexP8dK97GLuPMeta || {},
    component: () => import("/vercel/path0/pages/meeting/coaching/index.vue")
  },
  {
    name: "meeting-coaching-search",
    path: "/meeting/coaching/search",
    meta: searchX4z6bkkpFnMeta || {},
    component: () => import("/vercel/path0/pages/meeting/coaching/search.vue")
  },
  {
    name: "meeting-feedback",
    path: "/meeting/feedback",
    meta: feedbackuEO4omixEWMeta || {},
    component: () => import("/vercel/path0/pages/meeting/feedback.vue")
  },
  {
    name: "meeting-owner-chat",
    path: "/meeting/owner/chat",
    meta: chatkYC5HtyZQLMeta || {},
    component: () => import("/vercel/path0/pages/meeting/owner/chat.vue")
  },
  {
    name: "meeting-owner",
    path: "/meeting/owner",
    meta: indexCTCKT4SMw2Meta || {},
    component: () => import("/vercel/path0/pages/meeting/owner/index.vue")
  },
  {
    name: "meeting-owner-search",
    path: "/meeting/owner/search",
    meta: searchFsagdMY29gMeta || {},
    component: () => import("/vercel/path0/pages/meeting/owner/search.vue")
  },
  {
    name: "meeting-pickup-chat",
    path: "/meeting/pickup/chat",
    meta: chatMyaYDPSiuhMeta || {},
    component: () => import("/vercel/path0/pages/meeting/pickup/chat.vue")
  },
  {
    name: "meeting-pickup",
    path: "/meeting/pickup",
    meta: index4DJddCeFO9Meta || {},
    component: () => import("/vercel/path0/pages/meeting/pickup/index.vue")
  },
  {
    name: "meeting-pickup-search",
    path: "/meeting/pickup/search",
    meta: searchqLD9uWdcxfMeta || {},
    component: () => import("/vercel/path0/pages/meeting/pickup/search.vue")
  },
  {
    name: "meeting-repair-chat",
    path: "/meeting/repair/chat",
    meta: chatAoVDUoKiTjMeta || {},
    component: () => import("/vercel/path0/pages/meeting/repair/chat.vue")
  },
  {
    name: "meeting-repair",
    path: "/meeting/repair",
    meta: indexCCOG9PC5XJMeta || {},
    component: () => import("/vercel/path0/pages/meeting/repair/index.vue")
  },
  {
    name: "meeting-repair-search",
    path: "/meeting/repair/search",
    meta: searchdVyRbngRiBMeta || {},
    component: () => import("/vercel/path0/pages/meeting/repair/search.vue")
  },
  {
    name: "meeting-rescue-chat",
    path: "/meeting/rescue/chat",
    meta: chatv0qszGZsYCMeta || {},
    component: () => import("/vercel/path0/pages/meeting/rescue/chat.vue")
  },
  {
    name: "meeting-rescue",
    path: "/meeting/rescue",
    meta: indexxNpEcTnNieMeta || {},
    component: () => import("/vercel/path0/pages/meeting/rescue/index.vue")
  },
  {
    name: "meeting-rescue-search",
    path: "/meeting/rescue/search",
    meta: searchK8o0dcnPmCMeta || {},
    component: () => import("/vercel/path0/pages/meeting/rescue/search.vue")
  },
  {
    name: "my-alarm",
    path: "/my/alarm",
    meta: alarmvqOuuFICjvMeta || {},
    component: () => import("/vercel/path0/pages/my/alarm.vue")
  },
  {
    name: "my-board",
    path: "/my/board",
    meta: boardQKNegkj13WMeta || {},
    component: () => import("/vercel/path0/pages/my/board.vue")
  },
  {
    name: "my-comment",
    path: "/my/comment",
    meta: comment64VhN4jLx4Meta || {},
    component: () => import("/vercel/path0/pages/my/comment.vue")
  },
  {
    name: "my-coupon",
    path: "/my/coupon",
    meta: couponXU7chmpesfMeta || {},
    component: () => import("/vercel/path0/pages/my/coupon.vue")
  },
  {
    name: "my-feedback",
    path: "/my/feedback",
    meta: feedbacklhL9Arpl7XMeta || {},
    component: () => import("/vercel/path0/pages/my/feedback.vue")
  },
  {
    name: "my-keep",
    path: "/my/keep",
    meta: keepLSSxRlFwPtMeta || {},
    component: () => import("/vercel/path0/pages/my/keep.vue")
  },
  {
    name: "my-market",
    path: "/my/market",
    meta: marketiEHQic5HgzMeta || {},
    component: () => import("/vercel/path0/pages/my/market.vue")
  },
  {
    name: "my-meeting",
    path: "/my/meeting",
    meta: meetingVRkZUcgqNdMeta || {},
    component: () => import("/vercel/path0/pages/my/meeting.vue")
  },
  {
    name: "my-pin",
    path: "/my/pin",
    meta: pintiF2aZxUWFMeta || {},
    component: () => import("/vercel/path0/pages/my/pin.vue")
  },
  {
    name: "my-point",
    path: "/my/point",
    meta: pointRQRTOh63KnMeta || {},
    component: () => import("/vercel/path0/pages/my/point.vue")
  },
  {
    name: "my-repairSkill",
    path: "/my/repairSkill",
    meta: repairSkill7TGRfj1IgXMeta || {},
    component: () => import("/vercel/path0/pages/my/repairSkill.vue")
  },
  {
    name: "my-review",
    path: "/my/review",
    meta: reviewSXOfnxCu0SMeta || {},
    component: () => import("/vercel/path0/pages/my/review.vue")
  },
  {
    name: "my-shop",
    path: "/my/shop",
    meta: shop4YM4EX21epMeta || {},
    component: () => import("/vercel/path0/pages/my/shop.vue")
  },
  {
    name: "my-spark",
    path: "/my/spark",
    meta: sparkEFRjFlPl8wMeta || {},
    component: () => import("/vercel/path0/pages/my/spark.vue")
  },
  {
    name: "point",
    path: "/point",
    meta: indexTItJgGVEb6Meta || {},
    component: () => import("/vercel/path0/pages/point/index.vue")
  },
  {
    name: "policy-board",
    path: "/policy/board",
    meta: boardRC1Fql1ogSMeta || {},
    component: () => import("/vercel/path0/pages/policy/board.vue")
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexvrx28GESt5Meta || {},
    component: () => import("/vercel/path0/pages/policy/index.vue")
  },
  {
    name: "policy-market",
    path: "/policy/market",
    meta: marketr1PeqTKQ6OMeta || {},
    component: () => import("/vercel/path0/pages/policy/market.vue")
  },
  {
    name: "policy-privacy",
    path: "/policy/privacy",
    meta: privacyRYXZGN4huRMeta || {},
    component: () => import("/vercel/path0/pages/policy/privacy.vue")
  },
  {
    name: "policy-terms",
    path: "/policy/terms",
    meta: termsicxjzeK7QGMeta || {},
    component: () => import("/vercel/path0/pages/policy/terms.vue")
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    meta: _91id_93FDV9aaSSaFMeta || {},
    component: () => import("/vercel/path0/pages/profile/[id].vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexSsrwCXeuTQMeta || {},
    component: () => import("/vercel/path0/pages/profile/index.vue")
  },
  {
    name: "report-motorcycle",
    path: "/report/motorcycle",
    meta: indexftjGK2FLAwMeta || {},
    component: () => import("/vercel/path0/pages/report/motorcycle/index.vue")
  },
  {
    name: "review-gear",
    path: "/review/gear",
    meta: indexe7OizcFKr4Meta || {},
    component: () => import("/vercel/path0/pages/review/gear/index.vue")
  },
  {
    name: "review-motorcycle",
    path: "/review/motorcycle",
    component: () => import("/vercel/path0/pages/review/motorcycle/index.vue")
  },
  {
    name: "review-motorcycle-list",
    path: "/review/motorcycle/list",
    component: () => import("/vercel/path0/pages/review/motorcycle/list.vue")
  },
  {
    name: "review-motorcycle-my",
    path: "/review/motorcycle/my",
    meta: index0YrmoYIrhWMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/my/index.vue")
  },
  {
    name: "review-motorcycle-search",
    path: "/review/motorcycle/search",
    component: () => import("/vercel/path0/pages/review/motorcycle/search/index.vue")
  },
  {
    name: "review-motorcycle-search-result",
    path: "/review/motorcycle/search/result",
    component: () => import("/vercel/path0/pages/review/motorcycle/search/result.vue")
  },
  {
    name: "review-motorcycle-write-confirm",
    path: "/review/motorcycle/write/confirm",
    meta: confirmaSlWTOBqOFMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/write/confirm.vue")
  },
  {
    name: "review-motorcycle-write",
    path: "/review/motorcycle/write",
    meta: index4h8Z3bqcfdMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/write/index.vue")
  },
  {
    name: "review-motorcycle-write-init",
    path: "/review/motorcycle/write/init",
    component: () => import("/vercel/path0/pages/review/motorcycle/write/init.vue")
  },
  {
    name: "review-motorcycle-write-search",
    path: "/review/motorcycle/write/search",
    meta: search4N3qS51VkgMeta || {},
    component: () => import("/vercel/path0/pages/review/motorcycle/write/search.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexDqV3Lxa5AvMeta || {},
    component: () => import("/vercel/path0/pages/search/index.vue")
  },
  {
    name: "search-result",
    path: "/search/result",
    meta: resultENSgNekA1BMeta || {},
    component: () => import("/vercel/path0/pages/search/result.vue")
  },
  {
    name: "setting-blind",
    path: "/setting/blind",
    meta: blindeTK1umDDBVMeta || {},
    component: () => import("/vercel/path0/pages/setting/blind.vue")
  },
  {
    name: "setting",
    path: "/setting",
    component: () => import("/vercel/path0/pages/setting/index.vue")
  },
  {
    name: "setting-security",
    path: "/setting/security",
    meta: securityaSOaHHrXJVMeta || {},
    component: () => import("/vercel/path0/pages/setting/security.vue")
  },
  {
    name: "settlement",
    path: "/settlement",
    meta: indexoSSFurIOMXMeta || {},
    component: () => import("/vercel/path0/pages/settlement/index.vue")
  },
  {
    name: "settlement-securityCheck",
    path: "/settlement/securityCheck",
    meta: securityCheckfeazJA5A8AMeta || {},
    component: () => import("/vercel/path0/pages/settlement/securityCheck.vue")
  },
  {
    name: "specCompare-result",
    path: "/specCompare/result",
    component: () => import("/vercel/path0/pages/specCompare/result.vue")
  },
  {
    name: "specCompare-search",
    path: "/specCompare/search",
    component: () => import("/vercel/path0/pages/specCompare/search.vue")
  },
  {
    name: "specCompare-select",
    path: "/specCompare/select",
    component: () => import("/vercel/path0/pages/specCompare/select.vue")
  }
]