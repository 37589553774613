<script setup lang="ts">
const { url } = useImageStorage()

const expandTrigger = ref(false)

const contactTel = ref('010-2521-2080')
const contactEmail = ref('contact@goodnodes.biz')
</script>

<template>
  <div class="w-full flex flex-col items-center gap-y-2 pt-2.5 px-5 pb-10 text-xs break-keep text-gray05Light dark:text-gray05Dark">
    <div class="w-full flex items-center gap-x-3">
      <span>
        {{ $t('footer.company') }}
      </span>
      <span>
        {{ $t('footer.representative') }}
      </span>
      <AButton
        custom-class="flex-row-reverse p-0 font-light"
        button-variant="ghost"
        button-color="black"
        button-size="xs"
        use-leading
        :image-url="url(true, '/assets/icon/arrow_down_small.svg')"
        :image-class="expandTrigger ? 'h-4 w-4 rotate-180 transition-transform duration-200 ease-in-out' : 'h-4 w-4 transition-transform duration-200 ease-in-out'"
        :button-text="'펼쳐보기'"
        @click="expandTrigger = !expandTrigger"
      />
    </div>
    <div
      v-if="expandTrigger"
      class="w-full flex flex-wrap items-center gap-y-2 gap-x-3"
      :class="expandTrigger ? 'h-full transition-height duration-200 ease-out' : 'h-0 transition-height duration-200 ease-out'"
    >
      <span>
        {{ $t('footer.address') }}
      </span>
      <p>
        <span>
          {{ $t('footer.contactTel') }}
        </span>
        <WMLink
          to="tel:{{ contactTel }}"
          inactive-class="hover:text-gray04Light dark:hover:text-gray04Dark transition-colors duration-200 ease-in-out"
        >
          {{ contactTel }}
        </WMLink>
      </p>
      <p>
        <span>
          {{ $t('footer.contactEmail') }}
        </span>
        <WMLink
          to="mailto:{{ contactEmail }}"
          inactive-class="hover:text-gray04Light dark:hover:text-gray04Dark transition-colors duration-200 ease-in-out"
        >
          {{ contactEmail }}
        </WMLink>
      </p>
      <p>
        {{ $t('footer.businessRegistrationNumber') }}
      </p>
      <WMLink
        to="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=5908703312"
        inactive-class="font-semibold hover:text-gray04Light dark:hover:text-gray04Dark transition-colors duration-200 ease-in-out"
        external
        size="xs"
        target="_blank"
      >
        {{ $t('footer.checkBusinessRegistrationNumber') }}
      </WMLink>
      <WMLink
        to="/policy/privacy"
        inactive-class="font-semibold hover:text-gray04Light dark:hover:text-gray04Dark transition-colors duration-200 ease-in-out"
        size="xs"
      >
        {{ $t('footer.privacyPolicy') }}
      </WMLink>
      <WMLink
        to="/policy/terms"
        inactive-class="font-semibold hover:text-gray04Light dark:hover:text-gray04Dark transition-colors duration-200 ease-in-out"
        size="xs"
      >
        {{ $t('footer.termsOfService') }}
      </WMLink>
    </div>
  </div>
</template>
