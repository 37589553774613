<script setup lang="ts">
const { url } = useImageStorage()

const { userData } = storeToRefs(useUserDataStore())

withDefaults(
  defineProps<{
    menuData?: Database['public']['Tables']['menuCategory']['Row'][]
  }>(),
  {
    menuData: () => ([] as Database['public']['Tables']['menuCategory']['Row'][]),
  },
)

defineEmits([
  'click:menu',
])

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  required: true,
})

const displayMenu = (index: number) => {
  if (userData.value?.id) {
    return index > 0 && index < 6
  }
  else {
    return index > 0 && index < 6 && index !== 3
  }
}
</script>

<template>
  <div>
    <WMAccordion
      :items="menuData?.map(item => ({
        ...item,
        to: item.to || undefined,
      }))"
      :ui="{ item: { padding: 'p-0' } }"
    >
      <template #default="{ item, index, open }">
        <WMButton
          v-show="displayMenu(index)"
          color="white"
          variant="ghost"
          :ui="{
            base: item.code !== 'review' ? 'border-t border-gray03Light dark:border-gray03Dark' : '',
            rounded: 'rounded-none',
            padding: { sm: 'p-0 py-4' },
            color: { white: { ghost: 'hover:bg-transparent dark:hover:bg-transparent' } },
          }"
          @click="() => $emit('click:menu', item.menuList.length && item.code !== 'setting', item.to)"
        >
          <span class="text-lg font-semibold turncate text-gray05Light dark:text-gray05Dark">
            {{ item.title }}
          </span>
          <template #trailing>
            <NuxtImg
              v-if="item.menuList.length && item.code !== 'setting'"
              :src="url(true, '/assets/icon/arrow_right_large.svg')"
              class="w-6 h-6 ms-auto transform transition-transform duration-200 text-gray05Light dark:text-gray05Dark"
              :class="[open && 'rotate-90']"
            />
          </template>
        </WMButton>
      </template>
      <template #item="{ item }">
        <WMVerticalNavigation
          v-if="item.menuList.length && item.code !== 'setting'"
          :links="item.menuList"
          :ui="{ active: 'text-green-500', badge: { size: 'md' } }"
          @click="() => menuOpenTrigger = false"
        >
          <template #default="{ link }">
            <div class="h-8 pl-8 group-hover:text-primary relative mt-2">
              <span class="text-base truncate text-gray05Light dark:text-gray05Dark">
                {{ link.title }}
              </span>
            </div>
          </template>
        </WMVerticalNavigation>
      </template>
    </WMAccordion>
    <WMDivider :ui="{ border: { base: 'border-gray03Light dark:border-gray03Dark' } }" />
  </div>
</template>
