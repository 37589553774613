<script setup lang="ts">
const loadingModalTrigger = defineModel('loadingModalTrigger', {
  type: Boolean,
  default: false,
})
</script>

<template>
  <WMModal
    v-model="loadingModalTrigger"
    overlay
    fullscreen
    prevent-close
    :ui="{ base: 'opacity-10 h-full w-dvw', background: 'bg-transparent dark:bg-transparent' }"
  >
    <div class="flex justify-center items-center h-screen">
      <Icon
        name="i-svg-spinners-pulse-multiple"
        class="w-28 h-28"
      />
    </div>
  </WMModal>
</template>
