<script setup lang="ts">
const toast = useToast()
const { t } = useI18n()
const { url } = useImageStorage()
const colorMode = useColorMode()

const { logout } = useFetchComposable()

const { userData, userCoreId, mainSearchExpertRadius, mainSearchLocationAddress, mainSearchLocationLatitude, mainSearchLocationLongitude } = storeToRefs(useUserDataStore())
const { integratedNotification } = storeToRefs(useNotificationsStore())

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  required: true,
})

const clickLogout = () => {
  logout()
  userData.value = null
  userCoreId.value = ''
  mainSearchExpertRadius.value = 3000
  mainSearchLocationAddress.value = '영등포구'
  mainSearchLocationLatitude.value = 37.526618
  mainSearchLocationLongitude.value = 126.919666

  menuOpenTrigger.value = false
  integratedNotification.value = false

  toast.add({ title: t('message.logoutSuccess.title'), description: t('message.logoutSuccess.description'), color: 'green', timeout: 2000 })
}

const clickWithdrawal = () => {
  menuOpenTrigger.value = false
  navigateTo('/login/withdrawal')
}
</script>

<template>
  <div class="flex items-center gap-10">
    <AButton
      custom-class="text-base text-gray05Light dark:text-gray05Dark"
      button-variant="ghost"
      button-size="lg"
      use-leading
      :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/logout.svg' : '/assets/icon/logout_dark.svg')"
      :image-size="20"
      image-class="text-gray05Light dark:text-gray05Dark"
      :button-text="$t('button.logout')"
      :ui="{ padding: { lg: 'px-0 py-0.5' } }"
      @click="clickLogout"
    />
    <AButton
      custom-class="text-base text-gray04Light dark:text-gray04Dark"
      button-variant="ghost"
      button-size="lg"
      use-leading
      :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/forbidden_account.svg' : '/assets/icon/forbidden_account_dark.svg')"
      :image-size="20"
      image-class="text-gray04Light dark:text-gray04Dark"
      :button-text="$t('button.withdrawal')"
      @click="clickWithdrawal"
    />
  </div>
</template>
