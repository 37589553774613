<script setup lang="ts">
const { url } = useImageStorage()
const colorMode = useColorMode()

const user = useSupabaseUser()

const { executeUserData } = useLoadUserData()

const { userData } = storeToRefs(useUserDataStore())
const { menuData } = storeToRefs(useMenuDataStore())

const menuOpenTrigger = defineModel('menuOpenTrigger', {
  type: Boolean,
  default: false,
})

const navigateCategoryUrl = (children: boolean, to: string) => {
  if (!children) {
    navigateTo(to)
    menuOpenTrigger.value = false
  }
}

watch(menuOpenTrigger, async () => {
  if (menuOpenTrigger.value) {
    await executeUserData()
  }
})
</script>

<template>
  <WMSlideover
    v-model="menuOpenTrigger"
    overlay
    side="left"
    :ui="{
      base: 'overflow-y-scroll',
      width: 'w-screen max-w-[390px]',
      background: 'bg-gray01Light dark:bg-gray01Dark',
    }"
  >
    <div class="w-full flex flex-col pt-5 px-5 pb-8 gap-y-4">
      <div class="flex justify-start mb-3">
        <AButton
          use-leading
          button-variant="ghost"
          :image-url="url(true, colorMode.value === 'light' ? '/assets/icon/close.svg' : '/assets/icon/close_dark.svg')"
          :image-size="20"
          :tooltip-text="$t('button.close')"
          @click="() => menuOpenTrigger = false"
        />
      </div>
      <LoginButton
        v-if="!user"
        class="max-w-[400px]"
        @click:login="() => menuOpenTrigger = false"
      />
      <WMDivider v-if="!user" />
      <MenuMy
        v-else-if="userData && menuData"
        v-model:menu-open-trigger="menuOpenTrigger"
        :menu-list="menuData[0]?.menuList"
      />
      <MenuCategory
        v-model:menu-open-trigger="menuOpenTrigger"
        :menu-data="menuData"
        @click:menu="(isChildren: boolean, to: string) => navigateCategoryUrl(isChildren, to)"
      />
      <div class="flex flex-col gap-y-4">
        <MenuSubCategory
          :menu-data="menuData"
          @click:menu="(isChildren: boolean, to: string) => navigateCategoryUrl(isChildren, to)"
        />
        <WMDivider :ui="{ border: { base: 'border-gray03Light dark:border-gray03Dark' } }" />
        <MenuAuthButton
          v-if="user"
          v-model:menu-open-trigger="menuOpenTrigger"
        />
      </div>
    </div>
  </WMSlideover>
</template>
