import { defineStore } from 'pinia'

type MenuCategory = Database['public']['Tables']['menuCategory']['Row']
type MenuList = Database['public']['Tables']['menuList']['Row']

export const useMenuDataStore = defineStore('menuDataStore', () => {
  /**
   * ! Pinia State !
   *
   * @param menuData 메뉴 정보
   *
   */

  const menuData = ref<(MenuCategory & { menuList: MenuList[] })[]>()

  return {
    menuData,
  }
}, {
  persist: {
    storage: piniaPluginPersistedstate.localStorage(),
  },
})
