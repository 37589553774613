<script setup lang="ts">
const { locale } = useLocale()

withDefaults(
  defineProps<{
    relativeTime?: boolean
    dateTime?: string | ComputedRef<string> | null
    fullDateTime?: boolean
    capitalDateTime?: boolean
  }>(),
  {
    relativeTime: false,
    dateTime: '',
    fullDateTime: true,
    capitalDateTime: true,
  },
)
</script>

<template>
  <div>
    <NuxtTime
      v-if="fullDateTime && !relativeTime"
      :datetime="dateTime"
      :locale="locale"
      :year="capitalDateTime ? 'numeric' : '2-digit'"
      :month="capitalDateTime ? 'numeric' : '2-digit'"
      :day="capitalDateTime ? 'numeric' : '2-digit'"
      :hour="capitalDateTime ? 'numeric' : '2-digit'"
      :minute="capitalDateTime ? 'numeric' : '2-digit'"
      :hour-cycle="capitalDateTime ? 'h23' : 'h12'"
    />
    <!-- :second="capitalDateTime ? 'numeric' : '2-digit'" -->
    <NuxtTime
      v-else-if="!fullDateTime && !relativeTime"
      :datetime="dateTime"
      :locale="locale"
      :year="capitalDateTime ? 'numeric' : '2-digit'"
      :month="capitalDateTime ? 'numeric' : '2-digit'"
      :day="capitalDateTime ? 'numeric' : '2-digit'"
    />
    <NuxtTime
      v-else-if="relativeTime"
      date-tested
      :datetime="dateTime"
      relative
    />
  </div>
</template>
