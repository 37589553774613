export const useLoadMenuData = () => {
  const { orderFetchData } = useFetchComposable()

  const { menuData } = storeToRefs(useMenuDataStore())

  const { refresh: refreshMenuData } = useAsyncData('menuData', async () => {
    const result = await orderFetchData('menuCategory', '*, menuList!menuGroup(*)', 'index', true, 'menuList')

    menuData.value = result as SerializeObject

    return result ?? []
  }, {
    dedupe: 'defer',
  })

  return {
    refreshMenuData,
  }
}
